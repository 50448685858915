<script>
import flatPickr from 'vue-flatpickr-component';
import { chartColors } from '@/util/chart/chart.util';
import { barCharDefaultOptions } from '@/util/chart/chart.util';
import { BCard, BCardHeader, BCardBody, BCardTitle } from 'bootstrap-vue';
import ChartjsComponentBarChart from '@core/components/chartjs/ChartjsComponentBarChart.vue';
import { findStatisticsMilestone } from '@/views/coin/analytics/analytics.coin';

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardTitle,
        flatPickr,
        ChartjsComponentBarChart
    },
    props: {
        orders: {
            type: Array,
            required: true
        }
    },
    emits: ['update-time'],
    data() {
        const { to_date, from_date } = this.findStatisticsMilestone({}, { reverse: true });
        return {
            barCharDefaultOptions,
            rangePicker: [to_date, from_date],
            min: 0,
            max: 100,
            stepSize: 10
        };
    },
    computed: {
        barCharData() {
            const labels = [];
            const dataTracker = [];

            if (this.orders.length) {
                for (let i = 0; i < this.orders.length; i++) {
                    const [d, m] = this.orders[i].date.split('-');
                    labels.push(`${d}/${m}`);
                    dataTracker.push(this.orders[i].count);
                }
            }

            return {
                labels,
                datasets: [
                    {
                        data: dataTracker,
                        backgroundColor: chartColors.successColorShade,
                        borderColor: 'transparent'
                    }
                ]
            };
        }
    },
    watch: {
        stepSize(sz) {
            this.barCharDefaultOptions.scales.yAxes[0].ticks.stepSize = sz;
        },
        min(chMin) {
            this.barCharDefaultOptions.scales.yAxes[0].ticks.min = chMin;
        },
        max(chMax) {
            this.barCharDefaultOptions.scales.yAxes[0].ticks.max = chMax;
        },
        orders: {
            handler(ordersList) {
                if (!ordersList.length) {
                    this.min = 0;
                    this.max = 100;
                    this.stepSize = 10;
                } else {
                    const counts = ordersList.map((order) => order.count);
                    const max = Math.max(...counts);
                    this.min = 0;
                    this.max = Math.pow(10, String(max).length);
                    this.stepSize = Math.floor(max * 0.1);
                }

                this.$nextTick(() => {
                    this.rerenderChart();
                });
            },
            immediate: true
        }
    },
    methods: {
        findStatisticsMilestone,

        changeInterval(rv) {
            this.$emit('update-time', rv);
        },
        rerenderChart() {
            this.$refs.barChartOrders.rerender();
        }
    }
};
</script>

<template>
    <b-card no-body>
        <b-card-header>
            <b-card-title>{{ $t('number_of_orders') }}</b-card-title>
            <!-- datepicker -->
            <div class="d-flex align-items-center" style="min-width: 250px">
                <feather-icon icon="CalendarIcon" size="16" />
                <flat-pickr
                    v-model="rangePicker"
                    @on-change="changeInterval"
                    :config="{ mode: 'range' }"
                    class="form-control flat-picker bg-transparent border-0 shadow-none"
                    placeholder="YYYY-MM-DD"
                />
            </div>
            <!-- datepicker -->
        </b-card-header>

        <!-- chart -->
        <b-card-body>
            <chartjs-component-bar-chart
                v-if="orders.length"
                ref="barChartOrders"
                :height="400"
                :data="barCharData"
                :options="barCharDefaultOptions"
            />
        </b-card-body>
    </b-card>
</template>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
