<script>
import { formatToPrice } from '@/util/filter/filter.util';
import CoinAnalyticsCard from '@/views/coin/analytics/elements/CoinAnalyticsCard.vue';

export default {
    name: 'CoinOrderCards',
    components: {
        CoinAnalyticsCard
    },
    props: {
        analytics: {
            type: Object,
            required: true
        }
    },
    methods: {
        formatToPrice
    }
};
</script>

<template>
    <div>
        <!--    THIN CARDS    -->
        <div class="thin__cards">
            <b-card class="d-flex align-items-center justify-content-between">
                <div>
                    <p class="thin__card-amount mb-0">
                        {{ formatToPrice(analytics.orders_count) }}
                    </p>
                    <span class="thin__card-name">{{ $t('coin_analytics.total_orders') }}</span>
                </div>
                <div class="thin__card-icon shopping-cart-bg">
                    <feather-icon class="shopping-cart-icon" icon="ShoppingCartIcon" size="26" />
                </div>
            </b-card>

            <b-card class="d-flex align-items-center justify-content-between">
                <div>
                    <p class="thin__card-amount mb-0">{{ formatToPrice(analytics.ordered) }}</p>
                    <span class="thin__card-name">{{ $t('coin_analytics.ordered') }}</span>
                </div>
                <div class="thin__card-icon clock-bg">
                    <feather-icon class="clock-icon" icon="ClockIcon" size="26" />
                </div>
            </b-card>

            <b-card class="d-flex align-items-center justify-content-between">
                <div>
                    <div class="d-flex align-items-center">
                        <img
                            src="../../../../assets/images/icons/coin.png"
                            :width="18"
                            :height="18"
                            alt="coin.png"
                            style="margin-right: 0.5rem"
                        />
                        <p class="thin__card-amount mb-0">{{ formatToPrice(analytics.expense) }}</p>
                    </div>
                    <span class="thin__card-name">{{ $t('coin_analytics.total_expenses') }}</span>
                </div>
                <div class="thin__card-icon trending-down-bg">
                    <feather-icon class="trending-down-icon" icon="TrendingDownIcon" size="26" />
                </div>
            </b-card>
        </div>

        <!--    FAT CARDS    -->
        <div class="fat__cards">
            <CoinAnalyticsCard
                name="coin_analytics.accepted"
                icon="BriefcaseIcon"
                icon-color="primary"
                :amount="analytics.accepted"
            />
            <CoinAnalyticsCard
                icon="CheckCircleIcon"
                icon-color="success"
                name="coin_analytics.finished"
                :amount="analytics.finished"
            />
            <CoinAnalyticsCard
                name="coin_analytics.collect"
                icon="PackageIcon"
                icon-color="warning"
                :amount="analytics.collect"
            />
            <CoinAnalyticsCard
                name="coin_analytics.on_the_way"
                icon="TruckIcon"
                icon-color="warning"
                :amount="analytics.on_the_way"
            />
            <CoinAnalyticsCard
                name="coin_analytics.documentation"
                icon="FileTextIcon"
                icon-color="warning"
                :amount="analytics.documentation"
            />
            <CoinAnalyticsCard
                name="coin_analytics.delivered"
                icon="CheckIcon"
                icon-color="warning"
                :amount="analytics.delivered"
            />
            <CoinAnalyticsCard
                name="coin_analytics.client_rejected"
                icon="UserXIcon"
                icon-color="danger"
                :amount="analytics.client_rejected"
            />
            <CoinAnalyticsCard
                name="coin_analytics.merchant_rejected"
                icon="SlashIcon"
                icon-color="danger"
                :amount="analytics.merchant_rejected"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.thin__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 26px;
}

.thin__card {
    &-amount {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    &-name {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
    }

    &-icon {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
    }
}

.fat__cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 26px;
    grid-row-gap: 26px;
}

.shopping-cart-bg {
    background: rgba(115, 103, 240, 0.08);

    & svg {
        color: #7367f0;
    }
}

.clock-bg {
    background: rgba(255, 159, 67, 0.08);

    & svg {
        color: #ff9f43;
    }
}

.trending-down-bg {
    background: rgba(234, 84, 85, 0.08);

    & svg {
        color: #ea5455;
    }
}
</style>
