<script>
import { hasOwnProperty } from '@/util/object.util';
import ICountUp from 'vue-countup-v2';

export default {
    name: 'CoinAnalyticsCard',

    components: {
        ICountUp
    },

    props: {
        name: {
            type: String,
            default: () => ''
        },
        icon: {
            type: String,
            default: () => ''
        },
        amount: {
            type: [String, Number],
            default: () => 0
        },
        iconColor: {
            type: String,
            default: 'primary'
        }
    },

    data() {
        return {
            delay: 100,
            endVal: 120500,
            countUpOptions: {
                useEasing: true,
                useGrouping: true,
                separator: ' ',
                decimal: '.',
                prefix: '',
                suffix: ''
            }
        };
    },

    computed: {
        hasAmountSlot() {
            return hasOwnProperty(this.$slots, 'amount');
        },
        hasNameSlot() {
            return hasOwnProperty(this.$slots, 'name');
        }
    }
};
</script>

<template>
    <b-card>
        <div class="fat__card-details">
            <b-avatar class="fat__card-details-icon" :variant="`light-${iconColor}`" size="45">
                <feather-icon size="21" :icon="icon" />
            </b-avatar>
            <div style="margin-left: 0.75rem">
                <p class="fat__card-details-amount">
                    <slot name="amount"></slot>

                    <span v-if="!hasAmountSlot">
                        <i-count-up :delay="delay" :end-val="amount" :options="countUpOptions" />
                    </span>
                </p>
                <span class="fat__card-details-name">
                    <slot name="name"></slot>

                    <span v-if="!hasNameSlot">
                        {{ $t(name) }}
                    </span>
                </span>
            </div>
        </div>
        <div class="fat__card-graphs"></div>
    </b-card>
</template>

<style lang="scss" scoped>
.fat__card {
    &-details {
        display: flex;
        align-items: center;

        &-icon {
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;

            & .brief-case-icon {
                background-color: #fff;
            }
        }

        & p {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
        }

        & span {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
        }

        &-amount {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
        }
    }
}
</style>
