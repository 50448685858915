<script>
export default {
    name: 'CoinAnalyticsOrderHistory',

    props: {
        orders: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: 'Statistics'
        }
    },

    methods: {
        getUserName(user) {
            if (user['first_name'] || user['last_name']) {
                return user['first_name'] || '' + user['last_name'] || '';
            }
            return 'User => ' + user.id;
        }
    }
};
</script>

<template>
    <b-card class="card-transaction" no-body>
        <b-card-header>
            <b-card-title>{{ title }}</b-card-title>

            <!--            <b-dropdown class="chart-dropdown" no-caret right toggle-class="p-0" variant="link">-->
            <!--                <template #button-content>-->
            <!--                    <feather-icon-->
            <!--                        class="text-body cursor-pointer"-->
            <!--                        icon="MoreVerticalIcon"-->
            <!--                        size="18"-->
            <!--                    />-->
            <!--                </template>-->
            <!--                <b-dropdown-item href="#"> Last 28 Days </b-dropdown-item>-->
            <!--                <b-dropdown-item href="#"> Last Month </b-dropdown-item>-->
            <!--                <b-dropdown-item href="#"> Last Year </b-dropdown-item>-->
            <!--            </b-dropdown>-->
        </b-card-header>

        <b-card-body v-if="orders">
            <div v-for="item in orders" :key="item.id" class="transaction-item">
                <b-media no-body>
                    <b-media-aside class="align-items-center align-self-center">
                        <b-avatar rounded size="44" style="background: #f5f5f5; color: #7367f0">
                            <feather-icon icon="ShoppingCartIcon" size="24" variant="primary" />
                        </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                        <h6 class="transaction-title">Заказ#{{ item.id }}</h6>
                        <small v-if="item.user">{{ getUserName(item.user) }}</small>
                    </b-media-body>
                </b-media>
                <div class="font-weight-bolder text-right">
                    <img
                        :src="require('@/assets/images/icons/coin.png')"
                        alt=""
                        style="margin-right: 0.5rem"
                    />
                    {{ item.grand_total }}
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>

<style lang="scss" scoped>
.fat__card {
    &-details {
        display: flex;
        align-items: center;

        &-icon {
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;

            & .brief-case-icon {
                background-color: #fff;
            }
        }

        & p {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
        }

        & span {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
        }

        &-amount {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
        }
    }
}
</style>
