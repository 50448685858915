<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle.vue';
import { findStatisticsMilestone } from '@/views/coin/analytics/analytics.coin';
import CoinOrderCards from '@/views/coin/analytics/elements/CoinOrderCards.vue';
import CoinNumberOfOrders from '@/views/coin/analytics/elements/CoinNumberOfOrders.vue';
import CoinAnalyticsOrderHistory from '@/views/coin/analytics/elements/CoinAnalyticsOrderHistory.vue';

export default {
    name: 'AppCoinAnalytics',

    components: {
        CoinAnalyticsOrderHistory,
        PageTitle,
        CoinOrderCards,
        CoinNumberOfOrders
    },

    data() {
        return {
            delay: 1500,
            endVal: 120500,
            countUpOptions: {
                useEasing: true,
                useGrouping: true,
                separator: ' ',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            ordersAnalytics: [],
            ordersProcessingList: [],
            ordersNewList: [],
            analytics: {
                accepted: 0,
                client_rejected: 0,
                collect: 0,
                delivered: 0,
                documentation: 0,
                expense: 0,
                finished: 0,
                merchant_rejected: 0,
                on_the_way: 0,
                ordered: 0,
                orders_count: 0
            }
        };
    },

    async created() {
        await Promise.allSettled([
            this.getAnalyticsCards(),
            this.getAnalyticsByDate({}),
            this.getOrdersProcessingList(),
            this.getOrdersNewList()
        ]);
    },

    methods: {
        findStatisticsMilestone,
        async getAnalyticsCards() {
            try {
                const response = await api.coin.fetchCardsAmount();
                if (response.data.result) {
                    this.analytics = response.data.result;
                }
            } catch (e) {
                this.$toast(e?.response?.data?.message ?? e.message, {
                    type: 'error'
                });
            }
        },

        async getOrdersProcessingList() {
            const body = {
                page: 1,
                limit: 10
            };
            try {
                const response = await api.coin.fetchProcessingList(body);
                if (response.data.result) {
                    this.ordersProcessingList = response.data.result;
                }
            } catch (e) {
                this.$toast(e?.response?.data?.message ?? e.message, {
                    type: 'error'
                });
            }
        },

        async getOrdersNewList() {
            const body = {
                page: 1,
                limit: 10
            };
            try {
                const response = await api.coin.fetchNewList(body);
                if (response.data.result) {
                    this.ordersNewList = response.data.result;
                }
            } catch (e) {
                this.$toast(e?.response?.data?.message ?? e.message, {
                    type: 'error'
                });
            }
        },

        async getAnalyticsByDate({ fromDate, toDate }) {
            try {
                const body = this.findStatisticsMilestone({
                    from: fromDate,
                    to: toDate
                });

                const response = await api.coin.fetchStatistics(body);
                if (response.data.result) {
                    this.ordersAnalytics = response.data.result;
                }
            } catch (e) {
                this.$toast(e?.response?.data?.message ?? e.message, {
                    type: 'error'
                });
            }
        },

        async filterOrderStatistics(d) {
            await this.getAnalyticsByDate({
                fromDate: d[0],
                toDate: d[1]
            });
        }
    }
};
</script>

<template>
    <div class="analytics">
        <page-title>
            <template #title> {{ $t('product_analytics') }}</template>
        </page-title>

        <coin-order-cards :analytics="analytics" />

        <coin-number-of-orders :orders="ordersAnalytics" @update-time="filterOrderStatistics" />

        <b-row>
            <b-col class="cols-6">
                <coin-analytics-order-history :orders="ordersNewList" title="Новые заказы" />
            </b-col>
            <b-col class="cols-6">
                <coin-analytics-order-history
                    :orders="ordersProcessingList"
                    title="Заказы в процессе"
                />
            </b-col>
        </b-row>
    </div>
</template>
