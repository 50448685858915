import { dateFormatWithDash, dateProperties } from '@/util/date/calendar.util';

export function findStatisticsMilestone({ to, from }, { reverse = false } = { reverse: false }) {
    if (!from) {
        from = new Date();
        const { firstDayOfMonth } = dateProperties(from);
        from = firstDayOfMonth;
    }

    if (!to) {
        const { lastDayOfMonth } = dateProperties(new Date(from));
        to = lastDayOfMonth;
    }

    return {
        to_date: dateFormatWithDash(to, { monthIndex: 1, reverse }),
        from_date: dateFormatWithDash(from, { monthIndex: 1, reverse })
    };
}
